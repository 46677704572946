<template>
  <div class="userCheckoutPage">
    <h1>Checkout</h1>
    <span v-if="itemsOnCart === 0 || !fetchedDeliveryFees">
      <h4>No Items on Cart Yet</h4>
    </span>
    <span v-else-if="!userLoggedIn">
      <UserCart
        mode="checkout"
        :itemsOnCart="itemsOnCart"
        :userCart="userCart"
        :cart-id="cartId"
        :user-logged-in="userLoggedIn"
        :deliveryFees="deliveryFees"
        :enableLoyaltyPoints="true"
        :user-loyalty-points="userData.points"
        :deliveryMessageBackground="deliveryMessageBackground"
        :deliveryMessageTextColor="deliveryMessageTextColor"
        @openSnackbar="$emit('openSnackbar', $event)"
        @removeOrder="$emit('removeOrder', $event)"
        @checkout="() => {}"
        @openSignInPage="$emit('openSignInPage', $event)"
        @refreshCart="$emit('refreshCart')"
        @cartItemChanged="$emit('cartItemChanged')"
      />
      <br>
      <br>
      <black-button @click="$emit('openSignInPage', $event)" :fullwidth="true">Please login to continue checkout</black-button>
    </span>
    <span v-else>
      <UserCheckout
        :disableSubscriptionsStep="true"
        :userCart="userCart"
        :cartId="cartId"
        :user-logged-in="userLoggedIn"
        :deliveryFees="deliveryFees"
        :enableLoyaltyPoints="true"
        :user-loyalty-points="userData.points"
        :deliveryMessageBackground="deliveryMessageBackground"
        :deliveryMessageTextColor="deliveryMessageTextColor"
        :user-data="userData"
        :enableRewardful="false"
        :paymentRequestAvailable="false"
        :useExternalCheckout="true"
        @openSnackbar="$emit('openSnackbar', $event)"
        @openAddressesPage="openedPage = 'addresses'"
        @removeOrder="$emit('removeOrder', $event)"
        @makePurchase="handleCompletePurchase"
        @refreshCart="$emit('refreshCart')"
        @cartItemChanged="$emit('cartItemChanged')"
      />
    </span>
  </div>
</template>

<script>
import { UserCheckout, UserCart } from '@kiukicom/sidebar'
import httpClient from '@/services/httpClient'
import imgix from '@/services/imgix'
import BlackButton from '../components/BlackButton.vue'

export default {
  name: 'Checkout',
  components: {
    UserCheckout,
    UserCart,
    BlackButton
  },
  props: {
    deliveryMessageBackground: {
      required: false,
      type: String,
      default: '#3BAA5B'
    },
    deliveryMessageTextColor: {
      required: false,
      type: String,
      default: '#FFFFFF'
    }
  },
  computed: {
    userCartFinishedLoading () {
      return this.$store.state.sidebar.userCartFinishedLoading
    },
    userLoggedIn () {
      return this.$store.state.sidebar.userLoggedIn
    },
    currency () {
      return this.userCart.length
        ? this.userCart[0].currency !== undefined
          ? this.userCart[0].currency
          : 'GBP'
        : 'GBP'
    },
    userCartUpdated () {
      this.updateDeliveryFees()
      return this.$store.state.sidebar.userCartUpdated
    },
    isSingleSellerMarketPlace () {
      return process.env.VUE_APP_SINGLE_SELLER_MARKETPLACE === 'true'
    },
    cartId () {
      return this.$store.state.sidebar.cartId
    },
    userData () {
      return this.$store.state.sidebar.userData
    },
    userCart () {
      return this.$store.state.sidebar.userCart
    },
    itemsOnCart () {
      return this.userCart.reduce((acc, value) => {
        return acc + value.products.length
      }, 0)
    }
  },
  data () {
    return {
      mode: 'checkout',
      fetchedDeliveryFees: false,
      deliveryFees: [],
      imgix
    }
  },
  watch: {
    userCartFinishedLoading: {
      handler () {
        this.handleAddItemToCartFromUrl()
      }
    },
    cartId: {
      handler () {
      }
    },
    deliveryFees: {
      handler () {
      }
    },
    userCart: {
      handler () {
      },
      deep: true
    },
    userCartUpdated: {
      handler () {
        this.updateDeliveryFees()
      },
      deep: true
    }
  },
  mounted () {
    this.updateDeliveryFees()
  },
  methods: {
    handleAddItemToCartFromUrl () {
      // * if cartId is null or undefined, return
      if (this.cartId === null || this.cartId === undefined) { return }
      const params = this.$route.query
      // * If there's no param in the url, no need to go further
      if (params.productId === undefined && params.productId === null) { return }
      // * Check if item is already in cart, to avoid duplicating quantities
      const alreadyIncart = this.userCart.some((orgCart) => {
        return orgCart.products.some((cartProduct) => {
          return cartProduct.offering.id === params.productId
        })
      })
      if (alreadyIncart) { return }
      // Add Product to Cart
      httpClient
        .get(`/item/${params.productId}?links=is-offering-for`)
        .then((response) => {
          const offering = response.data
          httpClient
            .get(`/item/${offering.links[0].target}`)
            .then((itemResponse) => {
              const product = itemResponse.data
              product.offering = offering
              this.$emit('addItemToCart', product)
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handleCompletePurchase (purchaseData) {
      this.$emit('makePurchase', purchaseData)
      // console.log(purchaseData)
      this.$router.push(`/thank-you?purchase=${purchaseData.id}`)
    },
    updateDeliveryFees (delay = 0) {
      setTimeout(() => {
        if (!this.userIsLogged && this.cartId !== null) {
          httpClient
            .get(`/cart/${this.cartId}`)
            .then((response) => {
              this.deliveryFees = response.data.delivery_fees
              this.fetchedDeliveryFees = true
            }).catch((error) => {
              console.log(error)
            })
        } else if (this.cartId !== null) {
          httpClient
            .get('/cart/active')
            .then((response) => {
              this.deliveryFees = response.data.delivery_fees
              this.fetchedDeliveryFees = true
            }).catch((error) => {
              console.log(error)
            })
        } else {
          this.updateDeliveryFees(delay + 500)
        }
      }, 250 + delay)
    }
  },
  metaInfo () {
    return {
      title: 'Checkout',
      meta: [ { name: 'robots', content: 'noindex' } ]
    }
  }
}
</script>

<style scoped lang="stylus">
.userCheckoutPage {
  text-align: left;
  width: 100%;
  min-height: 90vh;
  max-width: 520px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 10px;
}
</style>
